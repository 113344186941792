.timeInputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 100%;
  height: 40px;
}

.customInput {
  flex-grow: 2;
  border: none;
  height: 100%;
  padding-left: 16px;
}

.customInput.highLightInfo {
  border: 1px solid red;
}

.clearBtn {
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Manrope";
  font-size: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
  color: #636578;
  cursor: pointer;
}