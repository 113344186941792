.redefinePercentages {
	font-style: italic;
	text-decoration: underline;
	font-size: 14px;
	margin-bottom: 20px;
	cursor: pointer;
	z-index: 10;
}

.redefinePercentagesPopupWrapper {
	width: 420px;
	padding: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	justify-content: center;
	flex-direction: column;

	.inputGroups {
		display: flex;
		margin-bottom: 20px;
	}

	.inputGroup {
		display: grid;
		row-gap: 10px;
		align-items: center;
		justify-content: center;
	}

	.label {
		font-size: 15px;
		font-weight: bold;
	}

	.inputBox {
		display: flex;
		width: 100%;
		align-items: center;
		justify-items: center;
		margin-bottom: 5px !important;
	}

	.buttonWrapper {
		grid-column: 1 / -1;
		display: flex;
		justify-content: flex-end;
		margin-right: 20px;
	}
}

.title {
	font-family: manrope;
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	color: #444652;
}

.tooltipWrapper {
	display: flex;
	flex-direction: column;
}


.comparingAgainstSpan {
	font-size: 12px;
	margin-left: 5px;
}

.oneLineTypo {
	white-space: nowrap;
	font-family: "manrope-bold";
}

.secondaryTypo {
	font-family: "mont light";
}
