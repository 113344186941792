.selectableCardCoTransfer {
	background-color: #fff;
	box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
	border-radius: 8px;
	margin-right: 10px;
	display: flex;
	flex-flow: column;
	cursor: pointer;

	&.selected {
		box-shadow: 0px 0px 14px rgba(7, 102, 115, 1);
	}

	&:hover {
		box-shadow: 0px 0px 14px rgba(7, 102, 115, 1);
	}

	&:nth-last-child(1) {
		margin-left: 0px;
	}

	.cardHeader {
		padding: 8px 13px 6px;
		border-bottom: 1px solid #f6f6f9;
		color: #444652;

		.title {
			width: 80%;

			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}

	.cardContent {
		width: 100%;
		height: 100%;
	}
}

.disabledCard {
	opacity: 0.61;
}

.selectableCardCoTransfer.skeleton {
	cursor: default;
}
