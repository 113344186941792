.dcEr.innerWrpr {
  width: 100%;

  .leftSec {
    width: 46%;
    border-right: 1px solid #f1f1f4;
    align-items: flex-end;

    .barWrpr {
      position: relative;
      justify-content: flex-end;
      width: 50px;
      margin: 0px 8px;
      height: 181.5px;
      .bar {
        background-color: #606BDF;
        width: 100%;
        border-radius: 5px;
        color: #fff;

        &.er {
          background-color: #87BCFE;
        }
      }
    }
  }

  .rightSec {
    width: 54%;
  }
}

.totalText {
  font-family: "manrope-bold";
  font-size: 15px;
  line-height: 20px
}

.percentageText {
  font-family: "manrope-reg";
  font-size: 12px;
  line-height: 16px;
}

.selectedItem {
  &:before {
    content: "";
    display: block;
    height: 10px;
    width: 16px;
    background-color: #4879f5;
    position: absolute;
    transform: translateY(-100%);
    border-radius: 50%;
    transition: "all 0.2s";
    bottom: -52%;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px
  } 
}
.selectedItemEr {
  &:before {
    content: "";
    display: block;
    height: 10px;
    width: 16px;
    background-color: #4879f5;
    position: absolute;
    transform: translateY(-100%);
    border-radius: 50%;
    transition: "all 0.2s";
    bottom: -52%;
    border-top-left-radius: 80px;
    border-top-right-radius: 80px
  } 
}