.accountUsers {
  background-color: #f5f5f5;
  height: 100%;
  overflow: hidden;

  .hdr {
    padding: 20px;
  }

  .listWrpr {
    padding: 0px 20px;
    height: calc(100% - 94px);
    overflow: auto;

    .line {
      box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
      margin: 0px 0px 10px;
      padding: 18px 24px;
      border-radius: 4px;
      background-color: #fff;
    }
  }
}