.dashboardPage {
  height: 100%;
  width: 100%;
  max-width: calc(100% - var(--sidebar-width));

  .content {
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    // display: flex;
    // overflow: hidden;
    overflow-y: auto; /* Allow vertical scrolling */
    position: relative;
  }
}