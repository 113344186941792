.leftPanel {
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
    border-radius: 8px 8px 0px 0px;
    height: calc(100vh - 15%) !important;    
    .header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 70px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        background: #fff;
    }
}

.itemsWrpr {
    width: 100%;
    margin: 15px;

    li:first-child {
        margin-top: 0px !important;
    }

    .sec {
        display: flex;
        background: #FFFFFF;
        box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        margin: 10px 0px 10px 0px;
        min-height: 40px;
        width: 100%;
        padding-top: 0px;
        padding-bottom: 2px;

        &.selected {
            border: 1px solid #4879F5;
        }
    }
}

.title {
    padding: 10px;
    font-family: 'manrope-bold';
    font-size: 12px;
    line-height: 16px;
    color: #444652;
}

.filterContainer {
    margin-top: 20px;
    padding-left: 20px;

    .filterTitle {
        margin-top: 5px;
        font-family: 'manrope-med';
        font-size: 12px;
        line-height: 16px;
        color: #444652;
    }
}

.chartContainer {
    height: calc(100vh - 38%) !important;    
}

.actionButton {
    background: #EEF3F7 !important;
    border-radius: 4px !important;
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
    border: none;

    &.prevBUtton {
        margin-left: 6px;
    }

    &.nextBUtton {
        margin-left: 3px;
    }
}

.totalContainer {
    // padding: 7px;
    float: right;
    height: 60px;
    background: #f5f5f5;
    opacity: .9;
    display: flex;
    align-content: center;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    border-radius: 5px;

    .totalCount {
        text-align: center;
        font-family: 'manrope-bold';
        font-size: 14px;
        line-height: 16px;
        color: #444652;
    }

    .totalText {
        padding: 5px 10px;
        font-family: 'manrope-bold';
        font-size: 12px;
        line-height: 16px;
        color: #444652;
    }
}

.chartBoxContainer {
    width: 100%;
    height:100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    .title {
        padding: 10px;
        font-family: 'manrope-bold';
        font-size: 12px;
        line-height: 16px;
        color: #444652;
    }

    .filterContainer {
        margin-top: 20px;
        padding-left: 20px;

        .filterTitle {
            margin-top: 5px;
            font-family: 'manrope-med';
            font-size: 12px;
            line-height: 16px;
            color: #444652;
        }
    }

    .chartContainer {
        height: 89%;
    }
}