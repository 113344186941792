.accountFacilities {
  padding: 20px;
  background-color: #f5f5f5;
  height: 100%;
  overflow: hidden;
  .pageHdr {

    .addBtn {
      background-color: transparentize($color: #00388d, $amount: 0.85);
      border: none;
      border-radius: 4px;
      padding: 12px 22px;
      cursor: pointer;
    }
  }
  .listWrpr {
    padding: 0px 20px;
    margin-top: 15px;
    height: calc(100% - 105px);
    overflow: auto;
    .line {
      box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
      margin: 0px 0px 10px;
      padding: 18px 24px;
      border-radius: 4px;
      background-color: #fff;
    }
  }
}
