.validationOverlay {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 9998;
}

.validation {
  border: 1px solid #e4e4e4;
  border-radius: 4px 4px 4px 4px;

  &.open {
    border-radius: 4px 4px 0px 0px;
    z-index: 9999;
  }

  .dummyInputWrpr {
    white-space: nowrap;

    &.open {
      z-index: 9999;
      background: white;
    }

    input {
      border: none !important;
      border-radius: 0 !important;

      &.multiple.hasValues {
        border-left: 1px solid #cecece !important;
      }
    }

    &.highLightInfoInput {
      border: 1px solid red;
      border-radius: 4px;
    }

    .opener {
      height: 12px;
      width: 12px;
      flex-shrink: 0;
      margin: -4px 10px 0px 10px;
      transform: rotate(45deg);
      border: 1px solid #8f8f8f;
      border-width: 0px 1px 1px 0px;
      transition: all 0.3s;
      cursor: pointer;

      &.open {
        transform: rotate(224deg);
        margin: 4px 10px 0px 10px;
      }
    }

    .selectedValContainer {
      padding: 0px 10px 0px 10px;

      &.empty {
        padding: 0px 0px 0px 0px;
      }
    }

    .selectedValue {
      padding: 4px 10px;
      margin-right: 4px;
      border: 1px solid rgb(214, 214, 214);
      border-radius: 4px;
    }
  }

  .listWrpr {
    position: absolute;
    bottom: -1px;
    transform: translateY(100%);
    background-color: #fff;
    z-index: 9999;
    width: calc(100% + 2px);
    left: -1px;
    max-height: 200px;
    overflow: auto;
    border: 1px solid #e4e4e4;
    border-top-width: 0px;
    border-radius: 0px 0px 4px 4px;

    .line {
      cursor: pointer;
      padding: 10px 10px;

      .editIcon {
        opacity: 0;
        transition: all 0.2s;
      }

      .editInputWrpr {
        position: absolute;
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border: 1px solid #e4e4e4;
        background: #fff;
        z-index: 1;
        color: black;

        input {
          width: 200px;
          height: 74%;
          padding: 10px;
          border-color: black;
        }

        .btn {
          background-color: #f0f0f0;
          border: 1px solid #fff;
          border-radius: 4px;
          padding: 4px 10px;
          width: 80px;
          cursor: pointer;

          &.saveBtn {
            background-color: #07621f;
            color: #fff;

            &:disabled {
              background-color: #f0f0f0;
              color: #fff;
              opacity: 0.7;
            }
          }
        }
      }

      &:hover {
        background-color: rgb(226, 226, 226);

        .editIcon {
          opacity: 1;
        }
      }

      &.isSelected {
        background-color: rgba(66, 104, 40, 0.25);
        // color: white;

        &:hover {
          background-color: rgb(66, 104, 40);
          color: #fff;

          svg {
            * {
              fill: #fff;
            }
          }
        }
      }
    }

    .addLine {
      position: sticky;
      bottom: 0px;
      z-index: 9999;
      background-color: #fff;
      border-top: 1px solid rgb(214, 214, 214);
      padding: 10px 10px;
      cursor: pointer;

      &.disabled {
        color: rgb(84, 84, 84, 0.5);
        pointer-events: none;
        cursor: default;
      }

      &:hover {
        background-color: #4879f5;
      }
    }

    .addLineLoader {
      margin-left: 12px;
    }
  }
}

.confirmUpdateWrpr {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;

  .confirmUpdate {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 380px;
    background-color: white;

    z-index: 9999;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    p {
      line-height: 28px;
      padding: 24px 32px 0px;
      color: #c97c0b;
    }

    .btn {
      background-color: #f0f0f0;
      border: 1px solid #fff;
      border-radius: 4px;
      padding: 10px 18px;
      width: 110px;
      cursor: pointer;

      &.updateBtn {
        background-color: #c97c0b;
        border: 1px solid #c97c0b;
        color: white;

        &.disabled {
          opacity: 9;
          background-color: #a59883;
          border: 1px solid #a59883;
          cursor: default;
        }
      }
    }
  }
}