.hdr {
  flex-flow: column;
  height: 100%;
  overflow-y: auto;
  box-shadow: -4px 0px 22px 0px rgb(0 0 0 / 10%);
  flex-shrink: 0;

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 25px;
    height: 95%;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    height: 100%;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #4879f5;
  }

  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 1px;
  }

  .logoWrpr {
    height: 110px;
    width: 100%;
    padding: 0px 26px;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
  }

  .linksWrpr {
    width: 100%;
    padding: 0px 14px;
  }

  .usernameWrpr {
    padding-bottom: 24px;
    border-top: 1px solid #e8e8eb;
    width: 100%;

    // .selectWrpr {
    //   position: relative;
    // }

    .expandedAccount {
      margin-top: -10px;
      // margin-bottom: 30px !important;
      margin-bottom: 0px !important;
      justify-content: center;
      text-align: center;
    }

    .settingsWrpr,
    .selectionsWrpr {
      padding: 24px 14px 24px;
      border-bottom: 1px solid #e8e8eb;
    }

    .selectionsWrpr {
      margin-bottom: 24px;
    }

    .withoutExpandSelection {
      margin-left: -7px;
      display: grid;
      justify-content: center;
      padding: 8px 32px;      
      width: 100%;      
      cursor: pointer;
      &:hover {
        border-radius: 8px;
        background-color: #f1f1f3;        
      }
    }

    .withoutExpandSelectionFacility {
      margin-top: 12px;
    }
  }

  .selectOpener {
    height: 44px;
    background: #eef3f7;
    border-radius: 6px;
    padding: 13px 18px;
    color: #444652;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.disabled {
      opacity: 0.4;
      cursor: no-drop;
    }
  }

  .selectOptionsWrpr {
    z-index: 1;
    background: transparent;
    padding: 8px 0px;
    max-height: 450px;
    overflow: auto;
    list-style-type: disc;
    width: 100%;

    & li {
      display: list-item;
      list-style: disc outside none;
    }

    .selectionOption {
      padding: 11px 20px;
      margin-left: 0px;
      cursor: pointer;
      white-space: nowrap;
      text-decoration: none;
      color: #444652;
      display: flex;
      list-style-type: disc;
      overflow: auto;
      margin-bottom: 3px;

      &.forUser {
        margin-left: 0;
      }

      &.forFacility {
        padding: 2px 20px;
        margin-left: 10px;
        margin-right: 10px;
      }

      &:hover:not(.selected) {
        background-color: #f1f1f3;
        border-radius: 5px;

        &.forUser {
          background-color: #2259c9;
          color: white;
        }
      }
    }

    .customLiDot {
      background-color: #d9d9d9;
      width: 6px;
      height: 6px;
      border-radius: 9999px;
      margin-right: 10px;
    }

    .selected {
      background-color: #2259c9;
      color: white;
      border-radius: 5px;
    }

    .notSelected {
      color: #2259c9;
      border-radius: 5px;
    }
  }

  .subHeader {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #b1b1b1;
    margin-bottom: 10px;
    margin-left: 15px;


    &.subHeaderTitle {
      text-align: center;
      margin-left: 0;
      cursor: default;
    }

    &.subHeaderProfile {
      margin-left: 37px;

      &.withoutExpand {
        margin-left: 0px;
        text-align: center;
      }
    }

    &.withoutExpand {
      margin-left: 0px;
      text-align: center;
      // margin-left: 8px;
    }
  }

  .selectOptionsWrpr.forUser {
    margin: 0px 22px;
    padding: 0;
    border-radius: 8px;
    background: #eef3f7;
    margin-top: 5px;
    width: auto;
    box-sizing: border-box;
  }

  .username {
    height: 56px;
    margin: 0px 22px;
    padding: 0px 12px;
    border-radius: 8px;
    background: #eef3f7;
    cursor: pointer;
  }

  .usernameNotExpand {
    background: none;
    padding: 0px 6px;
  }

  .usernameInnerSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    
    .userInfoWrapper {
      display: flex;

      img {
        border-radius: 9999px;
        width: 46px;
        height: 46px;
        border: 3px solid #c1d4e3;
      }

      .userNameRole {
        display: flex;
        flex-direction: column;
      }
    }
    .expandedUserInfoWrapper{
      margin-left: -10px;
    }

    .role {
      background: #4879f5;
      border-radius: 5px;
      font-family: "Manrope";
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 11px;
      width: 70px;
      padding: 3px;
      text-align: center;
      color: #ffffff;
      margin-top: 3px;
    }
  }
}

.selectMenuIcon {
  transform: rotate(0deg) scale(1);
  transition: all 0.25s;
  display: flex;
  align-items: center;

  &.active {
    transform: rotate(180deg) scale(1.15);
    transition: all 0.25s;
    color: #4879f5;
  }
}