.header {
	padding: 20px 18px 0px;

	.aoWrapper {
		height: 50px;
		border: 1px dashed #d9d9d9;
		width: 50%;
		border-radius: 8px;
		padding: 8px 14px;
		color: #444652;
	}
}

.plannedUnplanned {
	width: 100%;

	// .unplannedPlanned {
	.gradientText {
		background: -webkit-linear-gradient(#736ad1, #cc4b85);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.percentageWrapper {
		background-color: #efefef;
		border-radius: 40px;
		padding: 8px 14px;
		margin-right: auto;
	}

	.left {
		width: 56%;
		border-right: 1px solid #f1f1f4;
		color: #444652;
	}

	.right {
		width: 44%;
	}
}

.hospVsRehosp {
	width: 100%;

	.sec {
		border: 1px solid #e8e8eb;
		border-radius: 8px;

		&.selected {
			border: 1px solid #4879f5;

			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: -21px;
				transform: translateY(-50%);
				width: 8px;
				height: 16px;
				background-color: #4879f5;
				border-radius: 0px 10px 10px 0px;
			}
		}

		.numbersWrpr {
			height: 28px;
			width: 75px;
			background-color: #00baeb;
			color: #fff;
			border-radius: 5px;

			&.re {
				background-color: #5195dd;
			}
		}

		.bar {
			height: 12px;
			background-color: #00baeb;
			border-radius: 0px 20px 20px 0px;
			margin-top: 14px;

			&.re {
				background-color: #5195dd;
			}
		}
	}
}

.barLine {
	height: 29px;
	.pill {
		background-color: #eef3f7;
		width: 58px;
		height: 24px;
		border-radius: 5px;
	}
}

.projectionDaysSVGWrapper {
	position: absolute;
	right: 20px;
	top: 70px;
	cursor: pointer;
	z-index: 100;
	background: white;
	padding: 8px 12px;
	border-radius: 10px;
	transition: all 0.5s ease-in;
  display: flex;
  align-items: center;
}