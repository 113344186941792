.cardTooltipIconList {
    margin-bottom: -15px;

    button {
        width: 11px;
        height: 11px;
        padding: 0;
        margin-right: 0px;
        margin-top: 9px;
    }
}