$black: #222222;
$ptc: #07621f;

.overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.5px);
  z-index: 1;
}

.noFacilityDialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 8%);
  z-index: 1;
  max-height: 90%;
  //height: 100%;

  .hdr {
    height: 70px;
    padding: 0px 20px;
    border-bottom: 1px solid transparentize($color: #000000, $amount: 0.9);

    .closeBtn {
      background-color: transparentize($color: #000000, $amount: 0.9);
      height: 28px;
      width: 28px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.1s;

      &:hover {
        background-color: transparentize($color: #000000, $amount: 0.8);
      }
    }
  }

  .popupCntnt {
    width: 420px;
    // height: calc(80vh - 150px);
    padding: 26px 20px 10px;
    overflow: auto;

    &.stepOne {
      display: flex;
      flex-flow: column;
      height: calc(100% - 70px);
    }

    .multiple {
      >* {
        width: 50%;
        margin: 0px 5px;

        &:nth-child(1) {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    // attr(data-emoji)

    .swipeSelection {
      border: 1px solid transparentize($color: $ptc, $amount: 0.6);
      border-radius: 4px;
      overflow: hidden;

      .radioSec {
        padding: 12px 16px;
        background-color: transparentize($color: $ptc, $amount: 0.94);
        flex: 1;
        width: 50%;
        transition: all 0.175s;
        text-align: center;
        cursor: pointer;

        &.selected {
          background-color: transparentize($color: $ptc, $amount: 0.1);
          color: #fff;
        }
      }
    }

    .disabled {
      background-color: #000000;

      label {
        opacity: 0.5;
      }

      input {
        background-color: rgba(0, 0, 0, 0.05);
        cursor: not-allowed;
      }
    }
  }

  .toggleWrpr {
    border: 1px solid $ptc;
    justify-content: space-between;
    border-radius: 4px;

    .sec {
      text-align: center;
      padding: 10px 6px;
      flex: 1;
      cursor: pointer;
      border-right: 1px solid rgb(202, 202, 202);

      &:last-child {
        border-right-width: 0px;
      }

      &.selected {
        background-color: $ptc;
        color: white;
      }
    }

    &.vertical {
      .sec {
        border-right: none;
        border-bottom: 1px solid rgb(202, 202, 202);

        &:last-child {
          border-bottom-width: 0px;
        }
      }
    }
  }

  .step2 {
    width: 1200px;
    max-width: 90vw;
    padding: 26px 20px 10px;
    height: calc(100% - 70px);
    overflow: auto;
    display: flex;
    flex-flow: column;
  }

  .residentReturnedHdr {
    text-align: center;
    background: #07621f2b;
    padding: 16px 30px;
    font-size: 16px;
    font-family: "mont med";
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-radius: 4px;
    color: rgb(51, 51, 51);
  }

  .popupFtr {
    // padding: 0px 20px 26px;
    margin-top: auto;

    position: sticky;
    bottom: 0px;
    background: white;

    button {
      padding: 10px 30px;
      border-radius: 4px;
      border-color: transparent;
      letter-spacing: 1px;
      cursor: pointer;
      transition: all 0.2s;

      &.primary {
        background-color: $ptc;
        color: white;

        &:hover {
          background-color: transparentize($color: $ptc, $amount: 0.2);
        }
      }

      &.text {
        font-weight: 600;
        // text-decoration: underline;
        border-color: transparent;
        background-color: #07621f;
        color: #fff;

        &:hover {
          background-color: transparentize(#07621f, 0.25);
        }

        &.disabled {
          cursor: not-allowed;
          background-color: transparent;
          opacity: 0.5;
          background: none;
          color: #000000;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  .overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(3, 3, 3, 0.1);
  }

  .timePopup {
    position: fixed;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}

// New styles

.addPatient {
  .backWrpr {
    transform: rotate(180deg);
    flex-shrink: 0;
  }

  .stepOne {
    display: flex;
    flex-flow: column;
    height: calc(100% - 70px);
    width: 380px;
    overflow: auto;

    .selectionButton {
      display: flex;
      flex-flow: column;
      padding: 20px;
      border-radius: 4px;
      border: 1px solid transparentize($color: $ptc, $amount: 0.6);
      cursor: pointer;
      transition: all 0.175s;
      margin-bottom: 20px;

      &:hover {
        border-color: transparentize($color: $ptc, $amount: 0.8);
      }

      &.selected {
        background-color: $ptc;
        color: white;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.3;
        background: none;
        color: #000000;
      }
    }
  }

  .stepTwo {
    width: 410px;
    max-width: 90vw;
    padding: 26px 20px 10px;
    height: calc(100% - 70px);
    overflow: auto;
    display: flex;
    flex-flow: column;
  }

  .stepThree {
    width: 1200px;
    max-width: 90vw;
    padding: 26px 20px 10px;
    height: calc(100% - 70px);
    overflow: auto;
    display: flex;
    flex-flow: column;
  }
}



.lightText {
  opacity: 0.4;
}

.stepTwo {
    width: 410px;
    max-width: 90vw;
    padding: 26px 20px 10px;
    height: calc(100% - 70px);
    overflow: auto;
    display: flex;
    flex-flow: column;
  }