.popupOpenerWrapper {
	// position: relative;
	// padding: 10px;
	// border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: #4879f5;
		color: white;
	}

	&.selected {
		background-color: #4879f5;
		color: white;
	}
}

.projectionDaysSVGWrapper {
	// position: absolute;
	// left: 23px;
	// top: 70px;
	cursor: pointer;
	// z-index: 100;
	// color: #4879f5;
	padding: 10px 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	// background: white;

	&.active,
	&:hover {
		background: #4879f5;
		color: white;
	}
}

.clearWrapper {
	background: red;
	border-radius: 10px;
	position: absolute;
	top: -8px;
	right: -8px;
	width: 18px;
	height: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 101;
}

.clearWrapper.skeleton {
	background: transparent;
}

.secondSVGWrapper {
	color: #4879f5;
}

.projectionInfoFootnote {
	position: absolute;
	right: 25px;
	top: 117px;
	font-size: 14px;
	font-style: italic;
	font-family: manrope;
	color: #4879f5
}
