.validation {
  input {
    &.open {
      border-radius: 4px 4px 0px 0px;
    }
  }
  .list {
    position: absolute;
    bottom: -1px;
    transform: translateY(100%);
    background-color: #fff;
    z-index: 9999;
    width: calc(100% + 2px);
    left: -1px;
    max-height: 200px;
    overflow: auto;
    border: 1px solid #e4e4e4;
    border-top-width: 0px;
    border-radius: 0px 0px 4px 4px;

    .line {
      cursor: pointer;
      padding: 10px 10px;

      &:hover {
        background-color: #e2e2e2;
      }
    }
  }
}
