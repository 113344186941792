.infoContainer {
	height: 100%;
	width: 200px;
	border: none;
	background-color: #dae4fd;
	border-radius: 4px;	
	&:hover {
		border: 1px solid #4879f5;
		//background-color: #f1f1f3;
	}

	@media only screen and (max-width : 1380px) {
		width: 140px;
	}

	.infoWrapper {
		position: absolute;
		right: 10px;
		line-height: 0px;

		@media only screen and (max-width : 1380px) {
			right: 0px;
		}
	}
}

.infoContainer.skeleton {
	&:hover {
		border: none;
	}
}
