@import "./colors.scss";
@import "./mixin.scss";
@import "~react-toastify/dist/ReactToastify.css";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

// end reset.css

html,
body,
#root,
.App,
.pageWrpr {
    height: 100%;
    width: 100%;
}

*,
*::before,
*::after {
    position: relative;
    outline: none;
    box-sizing: border-box;
}

.db {
    display: block;
}

.dg {
    display: grid;
}

.df {
    display: flex;

    &.jsb {
        justify-content: space-between;
    }

    &.jcs {
        justify-content: flex-start;
    }
    
    &.jce {
        justify-content: flex-end;
    }

    &.ffc {
        flex-flow: column;
    }

    &.acc {
        align-items: center;
        justify-content: center;
    }

    &.aic {
        align-items: center;
    }

    &.ais {
        align-items: flex-start;
    }

    &.aie {
        align-items: flex-end;
    }

    &.jcc {
        justify-content: center;
    }

    .mta {
        margin-top: auto;
    }

    .mla {
        margin-left: auto;
    }
}

// Font families

@font-face {
    font-family: "manrope";
    src: url("./assets/fonts/Manrope/Manrope-Regular.woff2");
}

@font-face {
    font-family: "manrope-bold";
    src: url("./assets/fonts/Manrope/Manrope-Bold.ttf");
}

@font-face {
    font-family: "manrope-semi-bold";
    src: url("./assets/fonts/Manrope/Manrope-SemiBold.ttf");
}

@font-face {
    font-family: "manrope-med";
    src: url("./assets/fonts/Manrope/Manrope-Medium.ttf");
}

@font-face {
    font-family: "manrope-reg";
    src: url("./assets/fonts/Manrope/Manrope-Regular.ttf");
}

.ffmar {
    font-family: "manrope";
}

.ffmar-reg {
    //font-weight -400
    font-family: "manrope-reg";
}

.ffmar-bold {
    //font-weight -700
    font-family: "manrope-bold";
}

.ffmar-semi-bold {
    //font-weight -600
    font-family: "manrope-semi-bold";
}

.ffmar-med {
    //font-weight -500
    font-family: "manrope-med";
}

@font-face {
    font-family: "inter";
    src: url("./assets/fonts/Inter/Inter-VariableFont_slnt_wght.ttf");
}

.ffint {
    font-family: "inter";
}

.fw100 {
    font-variation-settings: "wght"100;
}

.fw200 {
    font-variation-settings: "wght"200;
}

.fw300 {
    font-variation-settings: "wght"300;
}

.fw400 {
    font-variation-settings: "wght"400;
}

.fw500 {
    font-variation-settings: "wght"500;
}

.fw600 {
    font-variation-settings: "wght"600;
}

.fw700 {
    font-variation-settings: "wght"700;
}

.fw800 {
    font-variation-settings: "wght"800;
}

@font-face {
    font-family: "mont light";
    src: url("./assets/fonts/Montserrat/Montserrat-Light.ttf");
}

.ffml {
    font-family: "mont light";
}

@font-face {
    font-family: "mont reg";
    src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}

.ffmr {
    font-family: "mont reg";
}

@font-face {
    font-family: "mont med";
    src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}

.ffmm {
    font-family: "mont med";
}

@font-face {
    font-family: "mont semi bold";
    src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}

.ffmsb {
    font-family: "mont semi bold";
}

@font-face {
    font-family: "abril fatface";
    src: url("./assets/fonts/Abril-Fatface/AbrilFatface-Regular.ttf");
}

.ffaff {
    font-family: "abril fatface";
}

.w100 {
    width: 100%;
}

.w80 {
    width: 80%;
}
.w70{
    width: 70%;
}
.w60{
    width: 60%;
}
.w50{
    width: 50%;
}
.w40{
    width: 40%;
}

// Font sizes

.fs65 {
    font-size: 65px;
}

.fs49 {
    font-size: 49px;
}

.fs36 {
    font-size: 36px;
}

.fs30 {
    font-size: 30px;
}

.fs25 {
    font-size: 25px;
}

.fs20 {
    font-size: 20px;
}

.fs18 {
    font-size: 18px;
}

.fs16 {
    font-size: 16px;
}

.fs15 {
    font-size: 15px;
}

.fs14 {
    font-size: 14px;
}

.fs13 {
    font-size: 13px;
}

.fs12 {
    font-size: 12px;
}

.fs11 {
    font-size: 11px;
}

.fs10 {
    font-size: 10px;
}

.fs9 {
    font-size: 9px;
}

.fs8 {
    font-size: 8px;
}

// Text colors

.btext {
    color: $black;
}

// Text transformations

.ttuc {
    text-transform: uppercase;
}

.ttcaps {
    text-transform: capitalize;
}

.tac {
    text-align: center;
}

.tar {
    text-align: right;
}

.inputWrpr {
    label {
        display: block;
        margin-bottom: 6px;
        letter-spacing: 0.3px;

        .sub {
            font-size: 0.7em;
        }
    }

    input:not([type="checkbox"]):not(.customInput),
    select {
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        background: none;
        border: 1px solid #e4e4e4;
        padding: 0px 16px;
    }
}

.p-t-80 {
    padding-top: 80px;
}

.p-t-85 {
    padding-top: 85px;
}

.p-t-90 {
    padding-top: 90px;
}

.p-t-95 {
    padding-top: 95px;
}

.mt-1 {
    margin-top: 1px;
}

.mt-2 {
    margin-top: 2px;
}

.mt-3 {
    margin-top: 3px;
}

.mt-4 {
    margin-top: 4px;
}

.mt-6 {
    margin-top: 6px;
}

.mt-10 {
    margin-top: 10px !important;
}

.cp {
    cursor: pointer;
}

.error {
    color: red !important;
}

.cp {
    cursor: pointer;
}

.rdrDefinedRangesWrapper .rdrInputRanges {
    position: relative;
}

// MATERIAL OVERRIDES

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.5);
}

.tableContainerADT {
    max-height: 330px;
    overflow-y: auto;
}

.timeZone-select {
    input {
        height: 36px;
        padding: 16.5px 14px;
    }
}

.subtitleOverall {
    font-style: italic !important;
    font-size: "0.5rem" !important;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton-text {
    animation: skeleton-text-loading 1.5s ease-in-out infinite alternate;
}
.MuiColorInput-Popover .MuiPaper-root{
    z-index: 10000 !important;    
}

@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 85%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

@keyframes skeleton-text-loading {
    0% {
        color: hsl(200, 20%, 85%);
    }

    100% {
        color: hsl(200, 20%, 95%);
    }
}

iframe#webpack-dev-server-client-overlay {
    display: none !important;
}

.table-filter-dropdown {
    border: 1px solid rgba(145, 158, 171, 0.32);
    z-index: 10;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.customFilterBoxContent {
    background-color: #ffffff;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.06);
    height: 30px;
    display: inline-block;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;

    &:hover {
        box-shadow: 0px 0px 14px rgba(72, 121, 245, 0.3);
    }

    &.selected {
        background-color: #4879f5;

        .customFilterBoxHeader {
            color: white;
        }
    }
}

.overall-additional-box {
    .customFilterBoxContent {
        margin-bottom: 15px;

        &.selected {
            margin-bottom: 0px !important;
        }

        &:hover {
            box-shadow: 0px 0px 14px #fa7226;
        }
    }
}

.customFilterBoxHeader {
    color: #4879f5;
    font-weight: bold;
    padding-left: 10px;
    font-family: manrope-bold;
    font-weight: 700;
    font-size: 15px;
    padding-right: 20px;
}


@keyframes bgAnimation {
    0% {
        background-size: 0 3px,
            3px 0,
            0 3px,
            3px 0;
    }

    25% {
        background-size: 100% 3px,
            3px 0,
            0 3px,
            3px 0;
    }

    50% {
        background-size: 100% 3px,
            3px 100%,
            0 3px,
            3px 0;
    }

    75% {
        background-size: 100% 3px,
            3px 100%,
            100% 3px,
            3px 0;
    }

    100% {
        background-size: 100% 3px,
            3px 100%,
            100% 3px,
            3px 100%;
    }
}

@keyframes bgAnimation2 {
    0% {
        background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
        background-position: 100% 0, 100% 0, 0 100%, 0 0;
    }

    25% {
        background-size: 0 3px, 3px 100%, 100% 3px, 3px 100%;
        // background-position: 100% 0, 100% 0, 0 100%, 0 0;        
        background-position: 100% 0, 100% 100%, 100% 100%, 0 0;
    }

    50% {
        background-size: 0 3px, 3px 0, 100% 3px, 3px 100%;
//        background-position: 100% 0, 100% 100%, 100% 100%, 0 0;
        background-position: 100% 100%, 100% 100%, 0 100%, 0 0;

    }

    75% {
        background-size: 0 3px, 3px 0, 0 3px, 3px 100%;
        background-position: 100% 100%, 100% 100%, 0 100%, 0 0;
    }

    100% {
        background-size: 0 3px, 3px 0, 0 3px, 3px 0;
        background-position: 100% 0, 100% 0, 0 100%, 0 0;        
    }
}
.dashboard-card-view {
    padding: 0.2em;
}
.scrollView .dashboard-card-view {
    // margin: 2rem auto; 
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, #92c7ff 28.23%, #4879f5 76.55%),
        linear-gradient(to bottom, #92c7ff 28.23%, #4879f5 76.55%),
        linear-gradient(to right, #92c7ff 28.23%, #4879f5 76.55%),
        linear-gradient(to bottom, #92c7ff 28.23%, #4879f5 76.55%);
    background-size: 100% 3px,
        3px 100%,
        100% 3px,
        3px 100%;
    background-position: 0 0,
        100% 0,
        100% 100%,
        0 100%;
    animation: bgAnimation 2s linear forwards, bgAnimation2 2s linear forwards 2s;

    //animation: bgAnimation 2s cubic-bezier(0.68, -0.55, 0.27, 1.55), bgAnimation2 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) 2s;
    // animation: bgAnimation 4s cubic-bezier(0.68, -0.55, 0.27, 1.55) forwards reverse;


    //animation: bgAnimation 1.25s cubic-bezier(0.19, 1, 0.22, 1), bgAnimation2 2s cubic-bezier(1, 0.19, 1, 0.22) 2s;
    // animation: bgAnimation 10s cubic-bezier(0.19, 1, 0.22, 1) 1s;
    // animation-play-state: running;
}

// .scrollView .dashboard-card-view {
//     animation-play-state: running;
// }