.slideCard {
	background: #ffffff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
	border-radius: 8px;
	&.selected {
		border: 1px solid #4879f5;
	}
	.slideCardContent {
		padding: 15px 18px !important;
		position: relative;
		& .textWrprReturn {
			margin-top: 4px;
		}
	}
	.checkboxButtonContainer {
		position: absolute;
		top: 0%;
		right: 0%;
	}
}

.labelCircle {
	width: 13px;
	height: 13px;
	border-radius: 6.5px;
	margin-right: 2px;
	margin-left: 2px;
}

.slideCard.skeleton {
	box-shadow: none;
}
