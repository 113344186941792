.notification {
  margin: 10px 0px;
  width: 390px;
  padding: 20px 20px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-family: "mont reg";

  .meter {
    content: "";
    height: 8px;
    width: 100%;
    background-color: transparent;
    position: absolute;
    bottom: 0px;
    left: 0px;

    .lever {
      background-color: transparentize($color: #fff, $amount: 0.7);
      height: 100%;
      transition: all 5s;
    }
  }

  &.success {
    background-color: #069b2e;
  }

  &.error {
    background-color: #ce1e36;
  }

  &.warning {
    background-color: #ffb915;
    //
  }
}
