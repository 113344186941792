.card {
	background-color: #fff;
	box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
	border-radius: 8px;
	margin-right: 10px;
	display: flex;
	flex-flow: column;

	&.disabled {
		background: #f2f2f2;
		cursor: not-allowed;
		pointer-events: none;
	}

	&:nth-last-child(1) {
		margin-left: 0px;
	}

	.cardHeaderContainer {
		display: grid;
		padding: 12px 10px 12px;
		border-bottom: 1px solid #f6f6f9;
		color: #444652;
	}

	.cardHeader {
		.secondaryTitle {
			color: #b3b3b3;
			font-size: 11px;
		}
	}

	.cardContent {
		width: 100%;
		height: 100%;
	}

	.cardTooltipIcon {
		margin-top: -15px;

		button {
			width: 11px;
			height: 11px;
			padding: 0;
			margin-right: 0px;
		}
	}
}
.tooltipContentContainerLog {
	padding: 6px;
	width:300px;	
	h6 {
		margin-bottom: 7px;
	}
	table, th, td {
		color:#808080;
		font-weight: 500;
		border: 1px solid #c4c6ce;
		border-collapse: collapse;
		padding: 5px 10px;
	}
}
.tooltipContentContainer {
	padding: 4px;

	svg path {
		fill: #4879f5;
	}
}

.tooltipContent {
	margin-top: -8px;
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 13px;
	color: #b3b3b3;
}
