@keyframes buttonHover {
  0% {
    transform: scale(1) rotate(0deg);
  }
  30% {
    transform: scale(1.02) rotate(0deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.btn {
  &.theme {
    background-color: #4879f5;
    color: white;
    border: none;
    transition: all 0.175s;

    &:hover {
      box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 20%);
      color: transparentize($color: #fff, $amount: 0.1);
      // animation: buttonHover 0.6s;
      // animation-iteration-count: 1;
    }
  }

  &.text {
    background-color: #fff;
    color: #4879f5;
    border: none;
    transition: all 0.175s;

    &:hover {
      box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 10%);
      color: transparentize($color: #4879f5, $amount: 0.1);
      // animation: buttonHover 0.6s;
      // animation-iteration-count: 1;
    }
  }

  &.large {
    padding: 0px 50px;
    height: 54px;
    border-radius: 8px;
    font-size: 15px;
    letter-spacing: 0.3px;
  }

  &.medium {
    padding: 0px 34px;
    height: 42px;
    border-radius: 8px;
    font-size: 13px;
    letter-spacing: 0.3px;
  }

  &:disabled {
    opacity: 0.5;
    filter: grayscale(0.5);
  }
}
