.ViewThroughADTCard {
  width: 100%;
  background-color: #EEF3F7;
  box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
  border-radius: 8px;
  margin-right: 10px;
  display: flex;
  flex-flow: column;

  &:nth-last-child(1) {
    margin-left: 0px;
  }

  .cardHeader {
    padding: 12px 20px 12px;
    color: #444652;

    .title {
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .cardContent {
    width: 100%;
    height: 100%;
    padding: 25px;
  }

  .cardAction {
    padding: 4px;
    margin-right: 11px;
    cursor: pointer;
  }

  .listContainer {
    padding: 10px;

    .listItem {
      margin-top: 13px;
      min-height: 71px;
      background: #FFFFFF;
      border-radius: 8px;
      .listIconButton{
        display: flex !important;
        flex-direction: column !important;
        text-align: center;
      }
      .listIconButton:hover {
        background: none;
      }

      .listIconButton:focus {
        background: none;
      }
    }

    .listItemHelperText {
      color: #b3b3b3;
      font-size: 11px;
    }

    .selectedItem {
      border: 1px solid #4879F5;
    }
  }

  .boxContainer {
    background: #FFFFFF;
    border-radius: 7px;

    .primaryText {
      font-family: 'manrope';
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #636578 !important;
    }

  }

  .itemButton {
    font-family: 'manrope';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #444652;
    height: 30px;
    min-width: 151px;
    background: #EEF3F7;
    border-radius: 5px;

    &.selected {
      background: #4879F5;
      color: #fff;
    }
  }

}