.tableWrpr {
  //   background-color: green;
  overflow: auto;
  height: 100%;
  table {
    width: 100%;

    thead {
      //   border-bottom: 1px solid #eef3f7;
      margin-bottom: 40px;
      th {
        padding: 20px;
        font-family: Manrope;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        height: 28px;
        text-align: left;
        padding: 6px 8px;
        white-space: nowrap;
        text-align: center;

        color: #444652;
      }
    }

    tbody {
      &:before {
        content: "@";
        display: block;
        line-height: 20px;
        text-indent: -99999px;
      }

      .separatorTr {
        height: 10px;
        border: none;
      }

      tr {
        background-color: #f5f5f5;
        border: 1px solid #e8e8eb;
        cursor: pointer;

        td {
          background-color: #f5f5f5;
          text-align: center;
          height: 34px;
          padding: 10px;
          color: #444652;
          font-weight: 500;
          white-space: nowrap;
          font-family: Lato;
          font-size: 15px;
          color: #808080;
          line-height: 1.4;
        }
      }
    }
  }
}
