@import "../colors.scss";

.login {
  h3 {
    margin-bottom: 50px;
  }

  .secWrpr {
    margin-bottom: 18px;

    .label {
      margin-bottom: 6px;
    }

    input {
      border: 1px solid #9b9b9b;
      width: 300px;
      height: 48px;
      padding: 0 16px;
      font-size: 16px;
      border-radius: 4px;
    }
  }

  button {
    border: none;
    height: 48px;
    width: 300px;
    border-radius: 4px;
    color: #fff;
    background: $ptc;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    cursor: pointer;
  }
}
