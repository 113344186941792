.doctor {
  width: 100%;
  padding: 20px;
  height: 346px;
  overflow: auto;

  .line {
    height: 38px;
    box-shadow: 0px 0px 14px 0px rgb(0 0 0 / 8%);
    border-radius: 8px;
    overflow: hidden;
    padding: 0px 18px;

    &:before {
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      background-color: #4879f5;
      position: absolute;
      left: 0px;
      transform: translateX(-100%);
      border-radius: 50%;
      transition: all 0.2s;
    }

    .sec {
      width: 44%;
      height: 100%;

      &.left {
        width: 56%;

        border-radius: 8px;
      }
    }

    &.selected {
      &:before {
        transform: translateX(-60%);
      }

      .left {
        border: 1px solid #4879f5;
      }
    }
  }
}