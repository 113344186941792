.didNotReturn {
	height: 100%;
	.box {
		display: flex;
	}

	.sec {
		width: 50%;
		margin: 4px 0px 0px 8px;
		border: 1px solid #e8e8eb;
		border-radius: 8px;
		color: #444652;
		height: 45px;
		padding: 0px 0px 1px 20px;

		&:last-child {
			margin-right: 8px;
		}

		&.selected {
			border: 1px solid #4879f5;
		}

		.secButtonText {
			font-family: "manrope-bold";
			line-height: 16px;
		}
	}

	.labelCircle {
		width: 13px;
		height: 13px;
		border-radius: 6.5px;
		margin-right: 2px;
		margin-left: 2px;
	}
}

.textWrprReturn {
	&::before {
		content: "";
		display: block;
		margin-right: 8px;
		width: 16px;
		height: 16px;
		background-color: #00baeb;
		border-radius: 20px;
	}
}

.textWrprNotReturn {
	&::before {
		content: "";
		display: block;
		margin-right: 8px;
		width: 16px;
		height: 16px;
		background-color: #2259c9;
		border-radius: 20px;
	}
}

// skeleton styles
.skeletonLabelText {
	width: 100px;
	height: 10px;
	border-radius: 5px;
}

.pieSkeletonWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 276px;
	width: 100%;
	padding: 0px;
}

.pieSkeleton {
	width: 260px;
	height: 260px;
	border-radius: 9999px;
}
