.taggedList {
    margin-right: 10px;
    background: #EEF3F7;
    border-radius: 3px;
    font-family: 'manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #636578;
    margin-top: 5px;
}