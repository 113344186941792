.snfFacilitySlider {
	background: #ffffff;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.14);
	border-radius: 8px;

	&.selected {
		border: 1px solid #4879f5;
	}

	.slideCardContent {
		padding: 15px 18px !important;
		position: relative;
	}

	.checkboxContainer {
		width: 100%;
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	.titleContainer {
		max-width: 90px;
		max-height: 40px;
	}

	.facilityButton {
		width: 65px;
		height: 20px;
		background: #076673;
		border-radius: 4px;
		font-family: "manrope-bold";
		font-weight: 700;
		font-size: 13px;
		line-height: 20px;
		color: #ffffff;
	}
}

.labelCircle {
	width: 13px;
	height: 13px;
	border-radius: 6.5px;
	margin-right: 2px;
	margin-left: 2px;
}

// skeleton stykes

.snfFacilitySlider {
	.slideCardContent.skeleton {
		padding-bottom: 0px !important;
	}
}
