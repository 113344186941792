.plannedUnplanned {
	width: 100%;

	.totalText {
		margin-top: -10px;
		height: 67px !important;
	}

	.gradientText {
		background: -webkit-linear-gradient(#736ad1, #cc4b85);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.gradientTextHospital {
		background: linear-gradient(180deg, #14aae9 0%, #8b47da 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.gradientTextPercentage {
		background: linear-gradient(125.15deg, #eab80e -7.95%, #ffd399 96.28%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
	.gradientTextPercentage.skeleton {
		background: none;
		-webkit-text-fill-color: unset;
	}

	.percentageWrapper {
		background-color: #efefef;
		border-radius: 22px;
		padding: 1px 15px 3px 10px;
		margin-right: auto;
	}

	.left {
		width: 56%;
		border-right: 1px solid #f1f1f4;
		color: #444652;
	}

	.right {
		width: 44%;
	}
}
