.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.5px);
    z-index: 1;
  }
  
  .addPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 8%);
    z-index: 99;
  
    .hdr {
      height: 70px;
      padding: 0px 20px;
      border-bottom: 1px solid transparentize($color: #000000, $amount: 0.9);
  
      .closeBtn {
        background-color: transparentize($color: #000000, $amount: 0.9);
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.1s;
  
        &:hover {
          background-color: transparentize($color: #000000, $amount: 0.8);
        }
      }
    }
  
    .popupCntnt {
      width: 400px;
      padding: 26px 20px 10px;
    }
  
    .popupFtr {
      padding: 0px 20px 26px;
  
      button {
        padding: 10px 30px;
        border-radius: 4px;
        border-color: transparent;
        letter-spacing: 1px;
        cursor: pointer;
        transition: all 0.2s;
  
        &.primary {
          background-color: #00388d;
          color: white;
  
          &:hover {
            background-color: transparentize($color: #00388d, $amount: 0.2);
          }
        }
  
        &.text {
          font-weight: 600;
          text-decoration: underline;
          border-color: transparent;
          background: none;
          color: transparentize($color: #000000, $amount: 0.3);
  
          &:hover {
            background-color: transparentize($color: #00388d, $amount: 0.92);
          }
        }
      }
    }
  }
  