.ninetyDays {
	height: 100%;
	min-height: 350px;
	.leftSec {
		width: 63%;
		height: 100%;
		padding-left: 11px;
		overflow: auto;
		@media (max-width: 1366px) {
			padding-left: 9px;
		}
		.ninetyDaysList {
			margin-bottom: 32px;
			width: 130px;
			margin-left: 5px;

			@media (max-width: 1366px) {
				margin-left: 0px;
			}

			.ninetyDaysLine {
				height: 34px;
				margin: 15px 0px;
				padding: 5px;
				cursor: pointer;
			}

			.ninetyDaysLineSelected {
				border: 1px solid #4879f5;
				border-radius: 8px;

				&:before {
					content: "";
					display: block;
					height: 16px;
					width: 16px;
					background-color: #4879f5;
					position: absolute;
					left: -8%;
					transform: translateX(-100%);
					border-radius: 50%;
					transition: all 0.2s;
				}
			}
		}

		.non30Days {
			color: #b3b3b3;
			max-width: 410px;
			margin: 14px auto 0px;
		}
	}

	.radialLegend {
		padding: 10px 14px 0px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		row-gap: 8px;

		@media (max-width: 1366px) {
			padding: 0px;
		}

		.icon {
			height: 12px;
			width: 12px;
			border-radius: 50%;
			margin-right: 8px;
		}
	}
}

// styles for skeleton
.ninetyDays {
	height: 100%;

	.leftSec {
		.ninetyDaysLinesWrapper.skeleton {
			display: flex;
			align-items: center;
			height: 34px;
			margin: 15px 0px;
			padding: 5px;
		}

		.ninetyDaysLine.skeleton {
			height: 34px;
			margin: 15px 0px;
			padding: 5px;
			width: 130px;
			cursor: default;
		}

		.ninetyDaysList.skeleton {
			width: auto;
		}
	}

	.radialLegend.skeleton {
		row-gap: 11px;
	}

	.percentLabel.short.skeleton {
		width: 70px;
		height: 10px;
		border-radius: 5px;
	}
	.percentLabel.long.skeleton {
		width: 110px;
		height: 10px;
		border-radius: 5px;
	}
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.circle {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #fff;
}

.wave1 {
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100px;
	height: 100px;
	border: 8px solid;
	border-radius: 50%;
}

.wave2 {
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 127px;
	height: 127px;
	border: 8px solid;
	border-radius: 50%;
}

.wave3 {
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 154px;
	height: 154px;
	border: 8px solid;
	border-radius: 50%;
}

.wave4 {
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 181px;
	height: 181px;
	border: 8px solid;
	border-radius: 50%;
}

.wave5 {
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 208px;
	height: 208px;
	border: 8px solid;
	border-radius: 50%;
}

.wave6 {
	position: absolute;
	top: 56%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 235px;
	height: 235px;
	border: 8px solid;
	border-radius: 50%;
}
