.DxCardList {
    width: 100%;
    padding: 20px;
    height: 346px;
    overflow: auto;

    .title {
        font-family: manrope;
        font-weight: 700;
        font-size: 15px;
        line-height: 20px;
        color: #444652;
    }

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 35px;
        border: 1px solid #E8E8EB;
        border-radius: 8px;

        .buttonContainerTotal {
            margin-left: 15px;            
        }

        @media (max-width: 1366px) {
            width: 68px;
            height: 50px;
        }
    }

    .listItem {
        .listItemButton {
            border: 1px transparent;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
            border-radius: 8px;
        }

        &.selected {
            .listItemButton {
                border: 1px solid #4879f5;
            }

            &:before {
                content: "";
                display: block;
                height: 16px;
                width: 16px;
                background-color: #4879f5;
                position: absolute;
                left: -3%;
                transform: translateX(-100%);
                border-radius: 50%;
                transition: all 0.2s;
            }
        }
    }

    .barWrpr {
        position: relative;
        max-height: 100%;
        justify-content: flex-end;
        width: 50px;
        margin: 0px 8px;

        @media (max-width: 1366px) {
            margin: 0px 5px;
            width: 40px;
        }

        .bar {
            background-color: #ff5b62;
            width: 100%;
            border-radius: 5px;
            color: #fff;

            &.er {
                background-color: #21d0c3;
            }
        }

        .totalTextBox {
            margin-bottom: -3px;
        }

        .percentageBox {
            border: 1px solid rgba(255, 255, 255, 0.48);
            border-radius: 5px;
            padding: 1px;
        }
    }
}