$layout-breakpoint-small: 960px;
$layout-breakpoint-medium: 960px;
$layout-breakpoint-large: 1920px;

.dayCardList {
	position: relative;
	overflow: hidden;

	.footerWrapper {
		height: 100px;

		.itemsWrpr {
			width: 100%;
			display: flex;
			justify-content: space-evenly;

			// @media (min-width: $layout-breakpoint-large) {
			//   column-gap: 22px;
			// }

			// @media (max-width: 1500px) {
			//   column-gap: 8px;
			//   padding-left: 14px !important;
			// }

			// @media (max-width: 1430px) {
			//   column-gap: 7px;
			// }

			// @media (max-width: 1366px) {
			//   column-gap: 7px;
			//   padding-left: 10px !important;
			// }

			.sec {
				display: flex;
				width: 9%;
				color: #444652;
				height: 45px;
				background: #f5f5f5;
				border-radius: 8px;
				justify-content: center;
				flex-direction: column;
				align-items: center;

				// @media (max-width: 1500px) {
				//   width: 40px;
				//   padding: 0px 12px;
				// }

				// @media (max-width: 1400px) {
				//   width: 38px;
				// }

				// @media (max-width: 1366px) {
				//   width: 37px;
				//   height: 42px;
				//   padding: 0px 10px;
				// }

				// @media (max-width: 1300px) {
				//   width: 35px;
				// }
				// @media (max-width: 1254px) {
				//   width: 32px;
				// }
				// @media (max-width: 1168px) {
				//   width: 28px;
				// }
				&:hover {
					border: 1px solid #4879f5;
				}
				&.selected {
					border: 1px solid #4879f5;

					&:before {
						content: "";
						display: block;
						height: 16px;
						width: 16px;
						background-color: #4879f5;
						border-radius: 50%;
						position: absolute;
						bottom: -130%;
						left: 50%;
						transform: translate(-50%, 50%);
					}
				}
			}

			.dayListCheckbox {
				margin-top: -8px;
			}

			.dayListLabel {
				padding: 4px 0px 0px 14px;
			}
		}
	}
}
