.safeDischargeAssLivingSlider {
	min-height: 350px;

	.totalCardContainer {
		padding-top: 0px;
		padding-right: 25px;
		padding-bottom: 00px;
		padding-left: 25px;
		margin-bottom: -25px;

		.totalCard {
			margin: 7px 0px 0px 15px;
		}
	}

	.sliderCardContainer {
		max-width: 90% !important;
	}

	.totalCard {
		background: #ffffff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
		border-radius: 8px;

		.totalCardContent {
			padding: 18px;
		}

		.totalCardLTitle {
			display: flex;
			justify-items: flex-start;
			align-items: center;
			padding: 2px;
			text-align: start;
		}

		.totalCardContentGrid {
			margin-top: 5px;
		}

		.totalCardLabelNumber {
			height: 78px !important;
			padding: 2px;
			background: #eef3f7;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.totalCardChart {
			display: flex;
			left: 20px;
			justify-content: flex-end;
		}
	}

	.checkboxButtonContainer {
		position: absolute;
		top: 0%;
		left: 2%;
	}

	.slideCard {
		background: #ffffff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
		border-radius: 8px;
		min-height: 100px !important;
		&.selected {
			border: 1px solid #4879f5;
		}

		.slideCardContent {
			padding: 15px 18px !important;
			position: relative;
		}

		.checkboxSliderButtonContainer {
			position: absolute;
			top: 0%;
			right: 0%;
		}
	}

	.labelCircle {
		width: 13px;
		height: 13px;
		border-radius: 6.5px;
		margin-right: 2px;
		margin-left: 2px;
	}

	.bottomButtonSec {
		margin-top: 10px;
	}

	.facilityButton {
		width: 30px;
		height: 20px;
		background: #076673;
		border-radius: 4px;
		font-family: "manrope-bold";
		font-weight: 700;
		font-size: 13px;
		line-height: 20px;
		color: #ffffff;
	}

	.textWrprReturn {
		margin-top: 4px;
		text-align: center;
	}

	.noDataFound {
		padding: 25px;
	}
}

// // skeleton styles
.safeDischargeAssLivingSlider {
	.totalCardContainer.skeleton {
		min-height: 140px;
		padding-right: 0;
		padding-left: 0;
		margin-right: 25px;
		margin-left: 25px;
		border-radius: 10px;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.slideCard.skeleton {
		box-shadow: none;
	}

	.totalCard.skeleton {
		box-shadow: none;
		min-height: 135px;
		width: 100%;
	}
}
