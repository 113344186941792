.diagnosticsCardList {
	width: 100%;
	padding: 20px;
	height: 346px;
	overflow: auto;

	.title {
		font-family: manrope;
		font-weight: 700;
		font-size: 15px;
		line-height: 20px;
		color: #444652;

		@media (max-width: 1424px) {
			font-size: 13px;
		}
	}

	.totalCount {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 38px;
		border: 1px solid #e8e8eb;
		border-radius: 8px;

		@media (max-width: 1424px) {
			width: 60px;
			height: 32px;
		}
	}

	.listItem {
		.listItemButton {
			border: 1px transparent;
			background: #ffffff;
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
			border-radius: 8px;
		}

		&.selected {
			.listItemButton {
				border: 1px solid #4879f5;
			}

			&:before {
				content: "";
				display: block;
				height: 16px;
				width: 16px;
				background-color: #4879f5;
				position: absolute;
				left: -3%;
				transform: translateX(-100%);
				border-radius: 50%;
				transition: all 0.2s;
			}
		}
	}

	.barWrpr {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 60px;
		margin: 0px 6px;

		@media (max-width: 1424px) {
			margin: 0px 1.5px;
			width: 53px;
			font-size: 12px;
		}

		.bar {
			background-color: #ff5b62;
			width: 100%;
			border-radius: 5px;
			color: #fff;
			padding: 4px;

			&.er {
				background-color: #21d0c3;
			}

			& span:first-child {
				color: #fff;
			}
		}

		.totalTextBox {
			margin-bottom: -3px;
		}

		.percentageBox {
			border: 1px solid rgba(255, 255, 255, 0.48);
			border-radius: 5px;
			padding: 1px;
		}
	}
}

.diagnosticsCardList {
	.listItem {
		.listItemButton.skeleton {
			border: 1px transparent;
			background: #ffffff;
			box-shadow: none;
			border-radius: 8px;
		}
	}
}
