.tableWrpr {
  //   background-color: green;
  overflow: auto;
  table {
    width: 100%;

    thead {
      //   border-bottom: 1px solid #eef3f7;
      th {
        height: 28px;
        font-weight: 800;
        text-align: left;
        padding: 6px 8px;
        color: #b3b3b3;
        white-space: nowrap;
      }
    }

    tr {
      border-bottom: 1px solid #eef3f7;

      td {
        height: 54px;
        padding: 24px 8px 10px;
        color: #444652;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}

.residentDetailsWrpr {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;

  .residentDetailsContent {
    height: 100%;
    max-height: calc(100% - 160px);
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translate(-50%, -0%);
    width: 90%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 22px 0px rgb(0 0 0 / 6%);

    .hdr {
      height: 70px;
      padding: 0px 20px;
      border-bottom: 1px solid #eef3f7;

      .closeBtn {
        background-color: rgba(0, 0, 0, 0.1);
        height: 28px;
        width: 28px;
        border-radius: 50%;
        cursor: pointer;
        transition: all 0.1s;
      }
    }

    .tableSec {
      height: calc(100% - 70px);
      overflow: auto;
      padding: 16px;
      overflow: auto;
    }
  }
}
