.dataInput {
  background-color: #fafafa;
  overflow: hidden;

  .header {
    padding-top: 11px;
    padding-bottom: 17px;
    padding-left: 19px;
    padding-right: 19px;
    height: 79px;
    background-color: #fff;
    border: 1px solid #e8e8eb;

    .searchWrpr {
      padding: 0px 24px 0px 20px;
      background-color: #fff;
      height: 50px;
      width: 350px;
      border-radius: 8px;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.06);

      .searchInpt {
        height: 100%;
        width: 100%;
        border: none;

        &::placeholder {
          color: #b3b3b3;
        }
      }
    }
  }

  .tableWrapper {
    background: #f5f5f5;
    padding: 20px;
  }

  .listWrpr {
    background-color: #fafafa;
    border-radius: 5px;
    padding: 40px 18px;
    height: calc(100vh - 119px);

    .addCensusStartWrpr {
      height: 100%;

      .dateInput {
        height: 54px;
        border: none;
        background: white;
        box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
        border-radius: 8px;
        padding: 0px 38px;
        font-size: 14px;
      }

      .censusButton {
        width: 100%;
      }

      .censusButtonPrev {
        width: 45%;
        margin-right: 5px;
      }

      .censusButtonSave {
        width: 45%;
        margin-left: 5px;
      }

      .censusButtonSaveHospital {
        margin-left: 55px;
      }
    }
  }

  .addButton {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
  }

  .roundBtn {
    margin-right: 18px;
    margin-top: 6px;
    cursor: pointer;
    background-color: transparent;
    border: none;

    .settingsFlyout {

      display: none;
      position: absolute;
      bottom: 0px;
      transform: translateY(100%);
      background-color: white;
      right: 0px;
      // padding: 14px;
      outline: none;
      // border: 1px solid #b1b1b1;
      border-radius: 8px;
      box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
      overflow: hidden;

      &.active {
        display: block;
        z-index: 1;
      }

      .settingsFlyoutItem {
        white-space: nowrap;
        font-size: 14px;
        padding: 16px 22px;
        text-align: initial;
        cursor: pointer;

        &:hover {
          background-color: rgba(123, 140, 184, 0.12);
        }
      }
    }
  }

  .dateInput {
    height: 54px;
    border: none;
    background: white;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;
    padding: 0px 38px;
    font-size: 14px;
    min-width: 180px;
  }

  // input[type="date"]::-webkit-calendar-picker-indicator {
  //   background: none;
  //   display: none;
  // }

  .censusInput {
    height: 54px;
    border: none;
    background: white;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;
    padding: 0px 38px;
    font-size: 14px;
  }

  @media only screen and (max-width : 1380px) {
    .dateInput {
      padding: 0px 10px;
      min-width: 120px;
    }

    .addButton {
      padding: 0px 20px;
    }
  }
}

.importantSpan {
  font-weight: bold;
  font-size: 18px;
}

.popupCntnt {
  margin-bottom: 20px;
}