.popupOpenerWrapper {
	position: relative;
	padding: 15px;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: #4879f5;
		color: white;
	}

	&.selected {
		background-color: #4879f5;
		color: white;
	}
}

.mainWrapper {
	width: 850px;
}

.headerWrapper {
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	border-bottom: 1px solid #eef3f7;

	.compareIconInnerWrapper {
		height: 42px;
		margin-right: 10px;
	}

	.header {
		display: flex;
		align-items: center;
	}
}

.compareIconCloseWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.accountsWrapper {
	padding: 15px;
	display: flex;
	flex-direction: column;
	overflow-x: auto;

	.account {
		border-radius: 5px;
		background-color: #eef3f7;
		padding: 5px 20px;
		display: inline-flex;
		margin-right: 10px;
		cursor: pointer;
		z-index: 10;

		&:hover,
		&.isActive {
			background-color: #4879f5;
			color: white;
		}
	}
}

.contentWrapper {
	display: flex;
	padding: 30px 15px;
	width: 100%;
}

.facilitiesWrapper {
	width: 35%;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background: #eef3f7;
	padding: 13px 20px;

	.checkboxWrapper {
		display: flex;
		max-height: 300px;
		overflow-y: auto;
		flex-direction: column;
		justify-content: space-between;
	}
}

.calendarWrapper {
	width: 75%;
}

.buttonContainer {
	padding: 10px;
	display: flex;
	z-index: 1;
	margin-left: 74%;

	.cancelButton {
		margin-left: 10px;
	}
}
