.totalCountCircle {
	position: relative;

	.htmlTooltip {
		position: absolute;
		right: 100px;
		top: 32px;
		&.multipleFacility{
			right: 120px;
		}
	}	

	.reportIcon {
		position: absolute;
		right:87px;
		top: 27px;
		&.multipleFacility{
			right:107px;
		}
	}

	.listIcon {
		position: absolute;
		right: 65px;
		top: 32px;
		&.multipleFacility{
			right:85px;
		}
	}

	.chartIcon {
		position: absolute;
		right: 40px;
		top: 32px;
		&.multipleFacility{
			right:60px;
		}
	}

	.buildingIcon{
		position: absolute;
		right: 35px;
		top: 32px;
	}

	.totalCircleAdmission {
		background: linear-gradient(249.91deg, #00baeb 28.23%, #736ad1 76.55%) !important;

		&.selected {
			box-shadow: 0 0 15px #736ad1;
		}
	}

	.totalCircleHospital {
		background: linear-gradient(249.91deg, #92c7ff 28.23%, #4879f5 76.55%);

		&.selected {
			box-shadow: 0 0 20px #4879f5;
		}
	}

	.communityTransfer {
		background: linear-gradient(249.91deg, #4cebeb 28.23%, #076673 76.55%);
		&.selected {
			box-shadow: 0 0 15px #108999;
		}
	}

	.deceased {
		background: linear-gradient(249.91deg, #ff5b62 28.23%, #6b0014 76.55%);
		&.selected {
			box-shadow: 0 0 10px #ff5b62;
		}
	}

	.circle {
		height: 256px;
		width: 256px;
		border-radius: 50%;

		&.projectionColor {
			background: linear-gradient(66.54deg, #e7b400 13.13%, #fed298 73.05%);
		}

		&.projection {
			height: 204px;
			width: 204px;
			&.selected {
				opacity: 0.9;
				background: #e7b400;
				box-shadow: 0 0 10px #e7b400;
			}
		}

		.innerCircle {
			height: calc(100% - 18px);
			width: calc(100% - 18px);
			background-color: #f5f5f5;
			border-radius: 50%;
			cursor: pointer;

			&.selected {
				opacity: 0.9;
				background: #f5f5f5 !important;
			}

			&:hover {
				background: #f5f5f5 !important;
				opacity: 0.9;
			}

			.percentageWrapper {
				background-color: #efefef;
				border-radius: 40px;
				padding: 8px 14px;

				.avg {
					color: #636578;
				}
			}
		}
	}
}

.totalCountCircle.skeleton {
	.circle {
		.innerCircle {
			cursor: default;
		}
	}
}
