.ADTCard {
    background-color: #fff;
    box-shadow: 0px 4px 14px 0px rgb(0 0 0 / 6%);
    border-radius: 8px;
    margin-right: 10px;
    display: flex;
    flex-flow: column;

    &.disabled {
        background: #F2F2F2;
        cursor: not-allowed;
        pointer-events: none;
    }

    &:nth-last-child(1) {
        margin-left: 0px;
    }

    .cardHeaderContainer {
        display: grid;
        padding: 12px 20px 12px;
        border-bottom: 1px solid #f6f6f9;
        color: #444652;
    }

    .cardHeader {
        .secondaryTitle {
            color: #b3b3b3;
            font-size: 11px;
        }
    }

    .cardContent {
        width: 100%;
        height: 100%;
    }

    .cardTooltipIcon {
        margin-top: -15px;

        button {
            width: 11px;
            height: 11px;
            padding: 0;
            margin-right: 0px;
        }
    }
}

.tooltipContentContainer {
    padding: 4px;

    svg path {
        fill: #4879F5;
    }
}

.tooltipContent {
    margin-top: -8px;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #B3B3B3;
}