.transferADTCardListTable {
  width: 100%;
  padding: 20px;
  height: 100%;
  overflow: auto;

  .line {
    height: 38px;
    background: #FFFFFF;
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border-radius: 8px;
    position: relative;

    &:before {
      content: "";
      display: block;
      height: 16px;
      width: 16px;
      background-color: #4879f5;
      position: absolute;
      left: -20px;
      transform: translateX(-100%);
      border-radius: 50%;
      transition: all 0.2s;
    }

    .sec {
      width: 44%;
      height: 100%;
      cursor: pointer;

      &.left {
        padding-left: 8px;
        width: 56%;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        border-radius: 8px;
        border-radius: 8px;
      }

      p {
        font-family: "manrope-med";
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #444652;
      }
    }

    &.selected {
      &:before {
        transform: translateX(-60%);
      }

      .left {
        border: 1px solid #4879f5;
      }
    }
  }
}