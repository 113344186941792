.dropDown {
  height: 54px;
  background-color: white;
  border-radius: 8px;
  z-index: 1;
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.06));

  &.medium {
    height: 42px;
  }

  .menuOpener {
    transition: all 0.25s;
    transform: rotate(90deg);
  }

  &.open {
    border-radius: 8px 8px 0px 0px;

    .menuOpener {
      transform: rotate(270deg);
    }
  }

  .menuOpener {
    transition: all 0.25s;
    transform: rotate(90deg);
  }

  .placeholder {
    height: 100%;
    width: 100%;
    padding: 0px 22px;
    color: #444652;
    font-weight: 600;
    letter-spacing: 0.5px;
    cursor: pointer;
  }

  .menu {
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    transform: translateY(100%);
    background-color: white;
    overflow: hidden;
    border-top: 1px solid #dfe4e7;

    .line {
      padding: 16px 22px;
      cursor: pointer;
      transition: all 0.25s;
      background-color: transparent;

      &:hover {
        background-color: rgba(123, 140, 184, 0.12);
      }

      &.selected {
        background-color: #4879f5;
        color: white;
      }
    }
  }

  .subOption {
    color: #5f5f5f;
    padding-left: 40px !important;
  }

  .mainOption {
    font-weight: bold;
  }
}
