.hospitalizationsList {
    width: 100%;
    padding: 20px;
    overflow: auto;

    button {
        font-family: 'manrope-bold';
        font-size: 10px;
        line-height: 14px;
        color: #fff;
    }

    .progressContainer div:last-child {
        margin-bottom: 9px !important;
    }
}

.non30Days {
    color: #b3b3b3;
    max-width: 410px;
    margin: 14px auto 0px;
}