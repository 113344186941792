.shiftsSec {
    position: relative;
    overflow: hidden;
    
    .footerWrapper {
        border-top: 1px solid #f6f6f9;
        height: 115px;

        .itemsWrpr {
            padding-left: 10px;
            padding-right: 20px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 8px;
            .sec {
                border: 1px solid #636578;                
                &.selected {
                    border: 1px solid #4879f5;
                }
                &:hover {
                    border: 1px solid #4879f5;
                }
            }
            @media (max-width: 1440px) {
                column-gap: 6px;
            }
            @media (max-width: 1366px) {
                column-gap: 8px;
            }            
        }

        .title {
            font-family: 'manrope-semi-bold';
            font-size: 10px;
            line-height: 14px;
            color: #B1B1B1;
        }
    }

    .shiftCheckbox {
        @media (max-width: 1366px) {
            margin-right: -5px;
        }
    }
}

.sec {
    width: 100%;
    margin: 4px 10px;
    border: 1px solid #e8e8eb;
    border-radius: 8px;
    color: #444652;
    height: 33px;
    padding: 7px 0px 7px 7px;
    position: relative;

    p {
        font-family: 'manrope-med';
        font-size: 14px;
        color: #444652;
    }

    @media (max-width: 1366px) {
        margin: 4px 0px;
    }

    &.selected {
        border: 1px solid #4879f5;

        &::before {
            content: "";
            display: block;
            height: 16px;
            width: 16px;
            background-color: #4879f5;
            border-radius: 50%;
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%, 50%);
        }
    }
}

.leftSec {
    width: 100%;
    border-right: 1px solid #f1f1f4;
    height: 100%;
    align-items: flex-end;

    .barWrpr {
        position: relative;
        height: 100%;
        justify-content: flex-end;
        width: 25%;
        margin: 0px 24px;
        margin-bottom: 10px;

        .bar {
            background-color: #ff5b62;
            width: 100%;
            border-radius: 5px;
            color: #fff;

            &.er {
                background-color: #21d0c3;
            }
        }

        .label {
            font-family: 'inter';
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #444652;
        }
    }
}