.navWrpr {
  width: 100%;

  a {
    display: flex;
    width: 100%;
    text-decoration: none;
    border-radius: 6px;
    padding: 6px;
    padding-right: 28px;
    color: #444652;
    margin-bottom: 4px;

    &.hasIcon {
      padding: 0px 13px;
      height: 46px;

      .iconWrpr {
        padding-right: 23px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.subIconWrpr {
          padding-right: 0;
        }

        svg {
          transform: scale(1);
          transition: all 0.1s;
        }
      }

      &.active {
        svg {
          transform: scale(1.15);
        }
      }
    }

    .openSubNav {
      transform: rotate(0deg);
      transition: all 0.25s;
    }

    &.active {
      background-color: #eef3f7;

      .openSubNav {
        transform: rotate(180deg);
        transition: all 0.25s;
      }

      &.main {
        background-color: #f1f1f3;
        background-color: #4879f5;
        color:  #fff;
        svg {
          color:  #fff;
        }
        &:before {
          background-color: white;
        }
      }

      &:before {
        background-color: white;
      }
    }
  }

  .subsWrpr {
    margin-top: 8px;
  }
}

.main {
  margin-bottom: 10px;
  
  &:hover {
    background-color: #f1f1f3;
  }
}

.iconsWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.active .iconWrpr {
  color: #4879f5;
}
.iconWrprWithoutExpand {
  padding: 0px;
  display: flex;
  justify-content: center;
}
.iconWrprWithoutExpand svg{
  margin-left: -1px;
  margin-top: -2px;
}
.iconWrpr {
  height: 24px;
}

.navLink {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: #f1f1f3;
  }

  &.active .subIconActiveSquare {
    background: #4879f5;
  }

  &.active,
  &:hover {
    .subIconWrapper {
      color: white;
    }

    .activeCircle {
      border: 2px solid #4879f5;
      width: 10px;
      height: 10px;
      border-radius: 99999px;
    }

    .subIconActiveSquare {
      background-color: #4879f5;
    }
  }
}

.subItemWrapper {
  display: flex;
  align-items: center;

  .subIconWrapper {
    height: 22px;
  }

  .subIconActiveSquare {
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 41px;
    height: 41px;
    border-radius: 5px;
  }
}
