.dateRangePickerClass {
    position: absolute;
    top: 61px;
    right: 0px;

}
.adtDatesContainer{
    font-family: 'manrope-reg';        
    margin-top:-15%;
    right:0%;
    font-size: 12px;
    white-space: nowrap;    
    overflow: hidden; 
    text-overflow: ellipsis;
    position: absolute;
    // @media (max-width: 1526px) {
    //     top:-20%;
    // }
    // @media (max-width: 1450px) {
    //     margin-top: -20%;
    // }
}
.adtDatesContainerStart{
    margin-top: 2px;
    margin-bottom: 3px;
}

.buttonContainer {
    padding: 10px;
    position: absolute;
    display: flex;
    z-index: 1;
    margin-top: 160px;
    margin-left: 50% !important;

    @media (min-width: 1650px) {
        margin-left: 60% !important;
    }
    @media (min-width: 2000px) {
        margin-left: 70% !important;
    }

    // @media (max-width: 1526px) {
    //     margin-top: 20%;
    // }

    // @media (max-width: 1450px) {
    //     margin-top: 20%;
    // }

    // @media (max-width: 1380px) {
    //     margin-top: 28%;        
    // }

    // @media (max-width: 1190px) {
    //     margin-top: 35%;
    //     margin-left: 25%;
    // }

    .cancelButton {
        margin-left: 10px;
    }
}