// TimeLogDisplay.module.scss

.container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-auto-rows: min-content;
	grid-gap: 20px;
	padding: 20px;
	max-width: 100%;
	overflow: hidden;
	box-sizing: border-box;
}

.card {
	background-color: #ffffff;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: fit-content;
}

.monthHeader {
	background-color: #4a90e2;
	color: #ffffff;
	padding: 15px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.monthTitle {
	margin: 0;
	font-size: 1.1em;
	font-weight: bold;
}

.dayCount {
	font-size: 0.8em;
	opacity: 0.9;
}

.dateList {
	max-height: 300px;
	overflow-y: auto;
}

.dateItem {
	padding: 10px 15px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #eee;
	transition: background-color 0.3s;

	&:hover {
		background-color: #f0f7ff;
	}
}

.dateLabel {
	font-weight: bold;
	font-size: 0.9em;
}

.timeCount {
	font-size: 0.8em;
	color: #666;
}

.timeList {
	background-color: #f9f9f9;
	padding: 10px 15px;
}

.timeItem {
	padding: 3px 0;
	font-size: 0.8em;
	color: #333;
}

@media (max-width: 600px) {
	.container {
		grid-template-columns: 1fr;
	}
}
