.totalOverall {
	width: 100%;
	padding-top: 30px;
	height: 325px;
	display: flex;
	align-items: center;
	justify-content: center;

	&.mainTotalCard {
		// background: #f5d948;
		height: 278px;		
	}

	&.withSubTotalCardMain {
		margin: 0px;
	}

	.totalOverallContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 17px;
	}

	.totalText {
		margin-top: -10px;
		margin-left: 12px;
	}

	.numberContainer {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.textNumberContainer {
		display: flex;
		flex-direction: column;
	}

	.textNumberContainer.textNumberSubContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;
		// margin-top: -18px;
	}

	.gradientTextPrimary {
		font-size: 60px;
		background: linear-gradient(180deg, #ff0000 0%, #f5d948 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		padding: 10px;

		&.withSubTotalCard {
			padding: 0px;
			line-height: 70px;
			display: flex;
			justify-content: center;
		}
	}

	.gradientMainTextSecondary {
		font-family: "manrope-bold";
		font-size: 22px;
		background: linear-gradient(180deg, #ff0000 0%, #f5d948 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}

	.gradientTextSecondary {
		font-family: "manrope-bold";
		font-size: 26px;
		background: linear-gradient(180deg, #ff0000 0%, #f5d948 100%);
		-webkit-background-clip: text;
		background-clip: text;
		text-fill-color: transparent;
		-webkit-text-fill-color: transparent;

		// @media (max-width: 1424px) {
		//     font-size: 24px;
		// }

		// @media (max-width: 1280px) {
		//     font-size: 18px;
		// }
	}

	.gradientTextSubtitle {
		padding: 2px 14px;
		background: #eef3f7;
		border-radius: 19px;
		font-family: "manrope-reg";
		font-size: 10px;
		line-height: 14px;
		text-align: center;
		margin-top: 4px;

		&.mainSubTitle {
			padding: 1px 6px;
			line-height: 10px;
			font-size: 9px;
			margin-top: -4px;
			margin-left: 35px;
			margin-top: 2px;
		}
	}

	.projectionContainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		// margin-left: 20px;
		border: 1px solid #f88e2f;
		border-radius: 8px;
		padding: 12px;

		@media (max-width: 1424px) {
			margin-left: 10px;
			padding: 8px;
		}

		@media (max-width: 1280px) {
			margin-left: 8px;
			padding: 6px;
		}

		&.totalProjection {
			margin-bottom: 38px;
		}

		.projectionTitle {
			font-family: "manrope-reg";
			font-size: 12px;
			line-height: 16px;
			text-align: center;

			&.projectionMainTitle {
				margin-top: 5px;
				margin-bottom: -10px;
			}
		}

		.projectionSubTitle {
			font-family: "manrope-bold";
			font-size: 16px;
			color: #444652;

			&.mainSubTitle {
				font-size: 18px;
				padding: 8px 0px 0px 0px;

				@media (max-width: 1424px) {
					font-size: 12px;
					padding: 8px 0px 0px 0px;
				}

				@media (max-width: 1280px) {
					font-size: 10px;
				}
			}
		}

		&.withSubTotalCard {
			padding: 5px;
			margin-bottom: 0px;
			min-width: 100%;

			@media (max-width: 1424px) {
				padding: 3px;
			}

			& .projectionTitle {
				@media (max-width: 1424px) {
					font-size: 13px;
				}

				@media (max-width: 1280px) {
					font-size: 12px;
				}
			}

			& .projectionSubTitle {
				@media (max-width: 1424px) {
					font-size: 15px;
				}

				@media (max-width: 1280px) {
					font-size: 14px;
				}
			}
		}
	}

	.left {
		width: 56%;
		border-right: 1px solid #f1f1f4;
		color: #444652;
	}

	.right {
		width: 44%;
	}
}

// skeleton styles

.totalOverall {
	.gradientTextPrimary.skeleton {
		background: none;
		text-fill-color: unset;
		-webkit-text-fill-color: unset;
	}

	.gradientMainTextSecondary.skeleton {
		background: none;
		text-fill-color: unset;
		-webkit-text-fill-color: unset;
	}

	.gradientTextSecondary.skeleton {
		background: none;
		text-fill-color: unset;
		-webkit-text-fill-color: unset;
	}

	.projectionContainer.skeleton {
		border-color: transparent;
		border-radius: 10px;
	}
}
