@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

.c-pointer {
  cursor: pointer;
}

.page-content {
  width: 100%;
}

.snf-facility-slider {
  width: 95%;
}

.assLiving-slider {
  width: 95%;

  .slick-prev:before,
  .slick-next:before {
    color: #666666;
  }

  .slick-dots {
    bottom: -15px;
  }

  .slick-list {
    .slick-slide {
      width: 165px !important;
      padding: 8px !important;
    }
  }
}

.no-sidebar-calendar .rdrDefinedRangesWrapper {
  display: none;
}

.no-sidebar-calendar .rdrCalendarWrapper,
.no-sidebar-calendar .rdrDateRangePickerWrapper {
  width: 100%;
}

.no-return-slider {
  padding: 45px;

  .slick-list {
    .slick-slide {
      width: 330px !important;
      padding: 10px !important;
    }
  }

  .slick-track {
    margin-left: 0;
  }

  .slick-arrow::before {
    color: #000;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }
}

.snf-facility-slider {
  //height: 350px;
  padding: 45px 20px 45px 45px;

  .slick-list {
    .slick-slide {
      height: 160px !important;
      width: 170px !important;
      padding: 10px !important;
    }
  }

  .slick-track {
    margin-left: 0;
  }

  .slick-arrow::before {
    color: #000;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }
}

html {
  --sidebar-width: 280px;
}
